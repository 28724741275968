/**
 * Created by zuozhuo on 2016/12/8.
 */
'use strict'

import _ from 'lodash'

class XEnumItem {
  constructor(value, text, meta = {}) {
    this.value = value
    this.text = text
    this.meta = meta
  }

  getValue() {
    return this.value
  }

  getText() {
    return this.text
  }

  equals(value) {
    if (value instanceof XEnumItem) {
      value = value.getValue()
    }
    return this.value === value
  }
}
class XEnum {
  constructor(enumDict) {
    this.enumItems = Object.values(enumDict)
    Object.keys(enumDict).forEach((key) => {
      this[key] = enumDict[key]
    })
  }

  findItem(itemValue) {
    if (itemValue instanceof XEnumItem) {
      itemValue = itemValue.getValue()
    }
    return this.enumItems.find((e) => e.getValue() === itemValue)
  }

  toText(value) {
    const item = _.find(this.enumItems, ['value', value])
    return item === undefined ? null : item.getText()
  }

  parseValueByText(text) {
    const exist = _.find(this.enumItems, (ele) => {
      return ele.getText() === text
    })
    if (exist === undefined) {
      return null
    } else {
      return exist.getValue()
    }
  }

  getOptions(excludeIndex) {
    if (!this.options) {
      return this.enumItems
        .filter((e) => excludeIndex !== e.getValue())
        .map((e) => {
          return {
            value: e.getValue(),
            label: e.getText()
          }
        })
    } else {
      return []
    }
  }

  getSpecOptions(specKey = []) {
    if (!this.options) {
      return this.enumItems
        .filter((e) => specKey.includes(e.getValue()))
        .map((e) => {
          return {
            value: e.getValue(),
            label: e.getText()
          }
        })
    } else {
      return []
    }
  }
}

const ENUM_GOODS_GROUP_TYPE = new XEnum({
  EMPTY_GROUP: new XEnumItem(-1, '空商品组'),
  HOME_GROUP: new XEnumItem(1, '首页广告组'),
  YEAR_GROUP: new XEnumItem(2, '年货节'),
  RATE_GROUP: new XEnumItem(3, '积分兑换排行耪'),
  POINT_LOVE_GROUP: new XEnumItem(4, '积分猜你喜欢'),
  SHOP_LOVE_GROUP: new XEnumItem(5, '购物车猜你喜欢'),
  RETURN_POINTS_GROUP: new XEnumItem(6, '购物返积分'),
  POINT_LOOK_GROUP: new XEnumItem(7, '积分查看更多'),
  POINT_YOUHUI_GROUP: new XEnumItem(8, '积分超值兑换'),
  USER_RECOMMEND_GROUP: new XEnumItem(9, '个人中心为你推荐'),
  SPECIAL_TOP_GROUP: new XEnumItem(10, '专场顶部轮播'),
  SPECIAL_DIGITAL_GROUP: new XEnumItem(11, '专场数码家电'),
  SPECIAL_BEAUTY_GROUP: new XEnumItem(12, '专场美妆护肤'),
  SPECIAL_WATCH_GROUP: new XEnumItem(13, '专场手表首饰'),
  SPECIAL_SALE_GROUP: new XEnumItem(14, '专场今日特卖'),
  TIME_LIMIT_GROUP: new XEnumItem(15, '限时秒杀'),
  BRAND_KANGJIA_GROUP: new XEnumItem(16, '康佳专场'),
  BRAND_YIMI_GROUP: new XEnumItem(17, '一米专场'),
  BRAND_TAIHUANGZI_GROUP: new XEnumItem(18, '台皇子专场'),
  BRAND_GEKANUO_GROUP: new XEnumItem(24, '格卡诺专场'),
  BRAND_XILINIU_GROUP: new XEnumItem(25, '喜力牛专场'),
  BRAND_KABAOOU_GROUP: new XEnumItem(26, '卡宝欧专场'),
  BRAND_CHUANGWEI_GROUP: new XEnumItem(43, '创维专场'),
  HOME_CATEGORY_BESTSALE_GROUP: new XEnumItem(19, '首页-热销榜单'),
  HOME_CATEGORY_XINPIN_GROUP: new XEnumItem(20, '首页-新品惠萃'),
  HOME_CATEGORY_KOUBEI_GROUP: new XEnumItem(21, '首页-口碑精选'),
  HOME_CATEGORY_ZHIXIAO_GROUP: new XEnumItem(22, '首页-厂家直销'),
  POINT_CENTER_0_CATEGORY_RECOMMEND_GROUP: new XEnumItem(27, '积分中心0-推荐页面'),
  POINT_CENTER_0_CATEGORY_MEIZHUANG_GROUP: new XEnumItem(28, '积分中心0-美妆页面'),
  POINT_CENTER_0_CATEGORY_DIANQI_GROUP: new XEnumItem(29, '积分中心0-电器页面'),
  POINT_CENTER_0_CATEGORY_JUJIA_GROUP: new XEnumItem(30, '积分中心0-居家页面'),
  POINT_CENTER_0_CATEGORY_MEISHI_GROUP: new XEnumItem(31, '积分中心0-美食页面'),
  POINT_CENTER_1_CATEGORY_RECOMMEND_GROUP: new XEnumItem(32, '积分中心1-推荐页面'),
  POINT_CENTER_1_CATEGORY_MEIZHUANG_GROUP: new XEnumItem(33, '积分中心1-美妆页面'),
  POINT_CENTER_1_CATEGORY_DIANQI_GROUP: new XEnumItem(34, '积分中心1-电器页面'),
  GUESS_YOUR_FAVOR_CUSTOM_SERVICE_GROUP: new XEnumItem(35, '客服-猜你喜欢'),
  POINT_CENTER_1_CATEGORY_MEISHI_GROUP: new XEnumItem(36, '积分中心1-美食页面'),
  POINT_CENTER_2_CATEGORY_RECOMMEND_GROUP: new XEnumItem(37, '积分中心2-推荐页面'),
  POINT_CENTER_2_CATEGORY_MEIZHUANG_GROUP: new XEnumItem(38, '积分中心2-美妆页面'),
  POINT_CENTER_2_CATEGORY_DIANQI_GROUP: new XEnumItem(39, '积分中心2-电器页面'),
  POINT_CENTER_2_CATEGORY_JUJIA_GROUP: new XEnumItem(40, '积分中心2-居家页面'),
  POINT_CENTER_2_CATEGORY_MEISHI_GROUP: new XEnumItem(41, '积分中心2-美食页面'),
  GOODS_PROFILE_RECOMMEND_GROUP: new XEnumItem(42, '商品详情页推荐'),
  GUESS_YOUR_FAVOR_POINT_CENTER_0_XIHUAN_GROUP: new XEnumItem(44, '积分中心-猜你喜欢'),
  GUESS_YOUR_FAVOR_POINT_CENTER_0_JINGXUAN_GROUP: new XEnumItem(45, '积分中心-好物精选'),
  GUESS_YOUR_FAVOR_POINT_CENTER_0_YANXUAN_GROUP: new XEnumItem(46, '积分中心-淘品严选'),
  GUESS_YOUR_FAVOR_POINT_CENTER_0_YOUXUAN_GROUP: new XEnumItem(47, '积分中心-超值优选'),
  GUESS_YOUR_FAVOR_POINT_CENTER_1_XIHUAN_GROUP: new XEnumItem(48, '积分中心-1-猜你喜欢'),
  GUESS_YOUR_FAVOR_POINT_CENTER_1_JINGXUAN_GROUP: new XEnumItem(49, '积分中心-1-好物精选'),
  GUESS_YOUR_FAVOR_POINT_CENTER_1_YANXUAN_GROUP: new XEnumItem(50, '积分中心-1-淘品严选'),
  GUESS_YOUR_FAVOR_POINT_CENTER_1_YOUXUAN_GROUP: new XEnumItem(51, '积分中心-1-超值优选'),
  GUESS_YOUR_FAVOR_POINT_CENTER_2_XIHUAN_GROUP: new XEnumItem(52, '积分中心-2-猜你喜欢'),
  GUESS_YOUR_FAVOR_POINT_CENTER_2_JINGXUAN_GROUP: new XEnumItem(53, '积分中心-2-好物精选'),
  GUESS_YOUR_FAVOR_POINT_CENTER_2_YANXUAN_GROUP: new XEnumItem(54, '积分中心-2-淘品严选'),
  GUESS_YOUR_FAVOR_POINT_CENTER_2_YOUXUAN_GROUP: new XEnumItem(55, '积分中心-2-超值优选'),
  GUESS_YOUR_FAVOR_MINE_XIHUAN_GROUP: new XEnumItem(56, '个人中心-猜你喜欢'),
  GUESS_YOUR_FAVOR_MINE_JINGXUAN_GROUP: new XEnumItem(57, '个人中心-好物精选'),
  GUESS_YOUR_FAVOR_MINE_YANXUAN_GROUP: new XEnumItem(58, '个人中心-淘品严选'),
  GUESS_YOUR_FAVOR_MINE_YOUXUAN_GROUP: new XEnumItem(59, '个人中心-超值优选'),
  GUESS_YOUR_FAVOR_MY_ORDER_XIHUAN_GROUP: new XEnumItem(60, '我的订单-猜你喜欢'),
  GUESS_YOUR_FAVOR_MY_ORDER_JINGXUAN_GROUP: new XEnumItem(61, '我的订单-好物精选'),
  GUESS_YOUR_FAVOR_MY_ORDER_YANXUAN_GROUP: new XEnumItem(62, '我的订单-淘品严选'),
  GUESS_YOUR_FAVOR_MY_ORDER_YOUXUAN_GROUP: new XEnumItem(63, '我的订单-超值优选'),
  GUESS_YOUR_FAVOR_DISTRIBUTE_XIHUAN_GROUP: new XEnumItem(64, '支付后-猜你喜欢'),
  GUESS_YOUR_FAVOR_DISTRIBUTE_JINGXUAN_GROUP: new XEnumItem(65, '导流-好物精选'),
  GUESS_YOUR_FAVOR_DISTRIBUTE_YANXUAN_GROUP: new XEnumItem(66, '导流-淘品严选'),
  GUESS_YOUR_FAVOR_DISTRIBUTE_YOUXUAN_GROUP: new XEnumItem(67, '导流-超值优选'),
  TAOKE_RUSH_GROUP: new XEnumItem(68, '疯抢排行'),
  BARGAIN_GROUP: new XEnumItem(69, '砍价商品'),
  BARGAIN_POINT_RECOMMEND_GROUP: new XEnumItem(70, '砍价-推荐页面'),
  BARGAIN_POINT_MEIZHUANG_GROUP: new XEnumItem(71, '砍价-美妆页面'),
  BARGAIN_POINT_DIANQI_GROUP: new XEnumItem(72, '砍价-电器页面'),
  BARGAIN_POINT_JUJIA_GROUP: new XEnumItem(73, '砍价-居家页面'),
  BARGAIN_POINT_MEISHI_GROUP: new XEnumItem(74, '砍价-美食页面'),
  WELFARE_FAST_MOVING_GROUP: new XEnumItem(75, '福利中心-快消品'),
  SEARCH_RECOMMEND_GROUP: new XEnumItem(76, '搜索推荐'),
  LIVE_GROUP: new XEnumItem(77, '直播商品'),
  WELFARE_BRAND_GROUP: new XEnumItem(78, '福利中心-品牌专场'),
  WELFARE_RETURN_GROUP: new XEnumItem(79, '福利中心-等价全返'),
  WELFARE_BARGAIN_GROUP: new XEnumItem(80, '福利中心-砍价'),
  WELFARE_POINT_GROUP: new XEnumItem(81, '福利中心-积分兑换'),
  WELFARE_SECKILL_GROUP: new XEnumItem(82, '福利中心-今日秒杀'),
  HOME_CATEGORY_DIANQI_GROUP: new XEnumItem(83, '首页-品质电器'),
  HOME_CATEGORY_JUJIA_GROUP: new XEnumItem(84, '首页-居家好物'),
  HOME_CATEGORY_MEIZHUANG_GROUP: new XEnumItem(85, '首页-美妆个护'),
  HOME_CATEGORY_SHISHANG_GROUP: new XEnumItem(94, '首页-轻奢时尚'),
  HOME_CATEGORY_MEISHI_GROUP: new XEnumItem(86, '首页-食品零食'),
  HOME_BRAND_XILINIU_GROUP: new XEnumItem(87, '福利中心-品牌专场-喜力牛专场'),
  HOME_BRAND_KANGJIA_GROUP: new XEnumItem(88, '福利中心-品牌专场-康佳专场'),
  HOME_BRAND_CHUANGWEI_GROUP: new XEnumItem(89, '福利中心-品牌专场-创维专场'),
  HOME_BRAND_YIMI_GROUP: new XEnumItem(90, '福利中心-品牌专场-一米专场'),
  HOME_BRAND_GEKANUO_GROUP: new XEnumItem(91, '福利中心-品牌专场-格卡诺专场'),
  HOME_BRAND_ZHIGAO_GROUP: new XEnumItem(92, '福利中心-品牌专场-志高专场'),
  HOME_BRAND_MEILING_GROUP: new XEnumItem(93, '福利中心-品牌专场-美菱专场'),
  SINGLE_PRODUCT_RECOMMEND_GROUP: new XEnumItem(95, '二类电商-单品-商品推荐'),
  BRAND_WINE_GROUP: new XEnumItem(96, '酒水专场'),
  SUMMER_GROUP: new XEnumItem(97, '消夏专场'),
  SINGLE_PRODUCT_ACTIVITY_GROUP: new XEnumItem(102, '单品活动页-单品'),
  SINGLE_PRODUCT_ACTIVITY_FEED_GROUP: new XEnumItem(103, '单品活动页-feed'),
  RUSH_EXPLODE_GROUP: new XEnumItem(109, '疯抢排行-爆品'),
  RUSH_RANK_GROUP: new XEnumItem(110, '疯抢排行-排行'),
  RUSH_NEW_GROUP: new XEnumItem(111, '疯抢排行-新品'),
  FESTIVAL_GROUP: new XEnumItem(112, '节日专场'),
  POINT_CENTER_PAGE_RECOMMEND: new XEnumItem(141, '积分卡片中间页好物推荐')
})

const ENUM_MALL_BRAND = new XEnum({
  DEV: new XEnumItem(-1, '开发调试'),
  TAOPIN: new XEnumItem(1, '淘品优选'),
  JINRI: new XEnumItem(2, '今日优选'),
  DUIDUODUO: new XEnumItem(3, '兑多多'),
  DUIHAOLI: new XEnumItem(4, '兑好礼'),
  JINRIDUI: new XEnumItem(7, '今日兑')
})

const ENUM_DATA_REPORT_SOURCES = new XEnum({
  DEV: new XEnumItem(-1, '开发调试'),
  DACU: new XEnumItem(3, '大促'),
  QIDESHENG: new XEnumItem(4, '启德晟'),
  DEBAO: new XEnumItem(5, '得宝')
})

const ENUM_WEBFUNNY_POINT_ID = new XEnum({
  GOODS_CLICK: new XEnumItem(6, '商品点击'),
  GOODS_EXPOSE: new XEnumItem(8, '商品曝光')
})

const ENUM_PAYMENT_TYPE = new XEnum({
  WX: new XEnumItem(1, '微信支付'),
  ALI: new XEnumItem(2, '支付宝'),
  ARRIVE: new XEnumItem(3, '货到付款'),
  WX_PUB: new XEnumItem(4, '公众号支付'),
  Wx_MiniApp_Pay: new XEnumItem(5, '微信小程序支付'),
  Ali_MiniApp_Pay: new XEnumItem(6, '支付宝小程序支付')
})

const ENUM_ORDER_STATUS = new XEnum({
  ALL: new XEnumItem(-1, '全部'),
  NOT_PAYED: new XEnumItem(0, '未付款'),
  NOT_DELIVER: new XEnumItem(1, '待发货'),
  DELIVERED: new XEnumItem(2, '已发货'),
  SIGNED: new XEnumItem(3, '已签收')
})

// 前端定义的枚举，用于做查询
const ENUM_QUERY_ORDER_STATUS = new XEnum({
  AFTERSALE: new XEnumItem(-1, '售后中'),
  WAIT_PAYED: new XEnumItem(0, '未付款'),
  WAIT_DELIVER: new XEnumItem(1, '待发货'),
  WAIT_RECV: new XEnumItem(2, '已发货')
})

const ENUM_ORDER_PAY_STATUS = new XEnum({
  UNPAY: new XEnumItem(0, '未付款'),
  PAYED: new XEnumItem(1, '已付款')
})

const ENUM_PAGE_SOURCE = new XEnum({
  UNKNOWN: new XEnumItem(-1, '未知'),
  HOME: new XEnumItem(1, '首页'),
  POINT_CENTER_0: new XEnumItem(2, '积分中心0样式'),
  POINT_CENTER_1: new XEnumItem(3, '积分中心1样式'),
  POINT_CENTER_2: new XEnumItem(4, '积分中心2样式')
})

const ENUM_APP_TYPE = new XEnum({
  UNKNOWN: new XEnumItem(-1, '未知'),
  H5: new XEnumItem(1, 'H5'),
  QUICKAPP: new XEnumItem(2, '快应用'),
  WX_PUB: new XEnumItem(3, '公众号'),
  APP: new XEnumItem(4, 'app'),
  WEAPP: new XEnumItem(5, '微信小程序'),
  PC: new XEnumItem(6, '电脑'),
  H5_IN_ALI: new XEnumItem(9, '支付宝H5'),
  H5_IN_ALI_MP: new XEnumItem(11, '支付宝小程序内嵌')
})

const ENUM_THEME_TYPE = new XEnum({
  ThemeOne: new XEnumItem(1, 'ThemeOne'),
  ThemeTwo: new XEnumItem(2, 'ThemeTwo'),
  ThemeThree: new XEnumItem(3, 'ThemeThree'),
  ThemeFour: new XEnumItem(4, 'ThemeFour'),
  ThemeFive: new XEnumItem(5, 'ThemeFive'),
  ThemeSix: new XEnumItem(6, 'ThemeSix')
})

const ENUM_BARGAIN_STATUS = new XEnum({
  PROGRESS: new XEnumItem(10, '进行中'),
  SUCCESS: new XEnumItem(20, '砍价成功'),
  FAILURE: new XEnumItem(30, '砍价失败')
})

const ENUM_ACTIVITY_TYPE = new XEnum({
  NONE: new XEnumItem(0, '没有活动'),
  BARGAIN: new XEnumItem(1, '砍价活动'),
  SECKILL: new XEnumItem(2, '秒杀'),
  FIRST_ORDER: new XEnumItem(5, '新人活动'),
  CONVENIENT_BUY: new XEnumItem(10, '顺手买')
})

const ENUM_SHARE_PIDS = new XEnum({
  BARGAIN: new XEnumItem('837498341687296', '砍价'),
  DEFAULT: new XEnumItem('862993977442304', '默认'),
  DOUBLE_ELEVEN: new XEnumItem('840661638053888', '双十一')
})

const ENUM_HOT_RANK_STATUS = new XEnum({
  HOTTEST: new XEnumItem(0, '爆'),
  HOT: new XEnumItem(1, '热'),
  NEW: new XEnumItem(2, '新'),
  NONE: new XEnumItem(3, '')
})

const ENUM_FILTER_GOODS_LIST = new XEnum({
  DEFAULT: new XEnumItem(0, '综合'),
  SALES: new XEnumItem(1, '销量'),
  PRICE: new XEnumItem(2, '价格')
})

const ENUM_SPRINGBOARD_TYPE = new XEnum({
  OTHER_WEAPP: new XEnumItem(0, '跳转其他小程序'),
  CONTACT: new XEnumItem(1, '打开客服'),
  PAYMENT: new XEnumItem(1, '收银台')
})

const ENUM_LINK_TYPE = new XEnum({
  LOCAL: new XEnumItem(1, '本站链接'),
  EXTERNAL: new XEnumItem(2, '外部链接')
})

const ENUM_GOODS_TYPE = new XEnum({
  MATERIAL: new XEnumItem(1, '实物'),
  MOBILE_RECHARGE: new XEnumItem(2, '话费'),
  DRAW_PRIZE: new XEnumItem(3, '大转盘抽奖'),
  PAY_ON_DELIVERY: new XEnumItem(4, '到付_货到付款'),
  PAY_ON_DELIVERY_FREE_RECEIVE: new XEnumItem(5, '到付_免费领取'),
  SINGLE_PRODUCT_PROMOTION: new XEnumItem(6, '二类_在线支付'),
  TENCENT_AD: new XEnumItem(7, '腾讯广告')
})

const ENUM_ORDER_TYPE = new XEnum({
  NORMAL: new XEnumItem(1, '普通'),
  RESEND: new XEnumItem(2, '补发'),
  MOBILE_RECHARGE: new XEnumItem(3, '话费'),
  DRAW_PRIZE: new XEnumItem(4, '大转盘抽奖')
})

const ENUM_ADVERTISEMENT_TYPE = new XEnum({
  H5: new XEnumItem(1, 'H5链接'),
  INNER_WEAPP: new XEnumItem(2, '小程序内部链接'),
  OTHER_WEAPP: new XEnumItem(3, '外部小程序链接')
})

const ENUM_JUMP_MINIAPP_LINKS = new XEnum({
  TPYX_BARGAIN_MP: new XEnumItem('weixin://dl/business/?t=5E5RVeOm8lp', '淘品砍价'),
  TPYX_LOTTERY_MP: new XEnumItem('weixin://dl/business/?t=aLaEAnKGsOj', '抽奖助手'),
  TPYX_WELFARE_MP: new XEnumItem(
    'https://prod-1g8ggadueb24d4bf-1305286747.tcloudbaseapp.com/cms-activities/index.html?activityId=cbddf0af609a501b07c673d4451a5eab&source=_cms_sms_',
    '淘福利商城'
  ),
  TPYX_WELFARE_MP_PAY_ON_DELIVERY: new XEnumItem(
    'https://prod-1g8ggadueb24d4bf-1305286747.tcloudbaseapp.com/cms-activities/index.html?activityId=28ee4e3e60a4c8dd1a17e01e13e05480&source=_cms_sms_',
    '淘福利商城_货到付款导流'
  ),
  TPYX_POINT_MP: new XEnumItem('weixin://dl/business/?t=KWcODVtMqEg', '积分兑购小程序')
})

const ENUM_BRANCH = new XEnum({
  BRANCH_A: new XEnumItem(1, '商城A版'),
  BRANCH_B: new XEnumItem(2, '商城B版'),
  BRANCH_LITE: new XEnumItem(3, '商城LITE版')
})

const ENUM_ADVERTISE_URL_TYPE = new XEnum({
  ACTIVITY: new XEnumItem(1, '活动页'),
  PROFILE: new XEnumItem(2, '详情页'),
  LINK: new XEnumItem(3, '链接'),
  NONE: new XEnumItem(4, '无'),
  MINI_PROGRAM: new XEnumItem(5, '小程序')
})

const ENUM_TRAFFIC_GUIDE_TYPE = new XEnum({
  DEFAULT: new XEnumItem(0, '默认'),
  MINI_PROGRAM: new XEnumItem(1, '小程序'),
  WEB_URL: new XEnumItem(2, '网址外链')
})

const ENUM_RP_EVENT_TYPE = new XEnum({
  GOODS_WATERFALL_CARD_EP: new XEnumItem('0', '商品瀑布流-商品卡片-曝光'),
  GOODS_WATERFALL_CARD_CLICK: new XEnumItem('1', '商品瀑布流-商品卡片-点击'),
  GOODS_DETAIL_PV: new XEnumItem('2', '商品详情页-PV'),
  GOODS_INFO_BUY_CLICK: new XEnumItem('3', '商品信息-立即购买'),
  GOODS_INFO_BAR_CLICK: new XEnumItem('4', '商品信息-通栏'),
  GOODS_HEAT_EP: new XEnumItem('5', '商品详情页-热度-曝光'),
  GOODS_DETAIL_HOME_CLICK: new XEnumItem('6', '商品详情页-首页-点击'),
  GOODS_DETAIL_CUSTOM_SERVICE_CLICK: new XEnumItem('7', '商品详情页-客服-点击'),
  GOODS_DETAIL_BUY_CLICK: new XEnumItem('8', '商品详情页-购买-点击'),
  ROUTE_TO_PAGE: new XEnumItem('9', '进入路由页'),
  GOODS_DETAIL_BACK_TO_TOP: new XEnumItem('10', '商品详情页-返回顶部-点击'),
  GOODS_HEAT_BUY_CLICK: new XEnumItem('11', '商品详情页-热度-点击购买'),
  FLOAT_BAR_CLICK: new XEnumItem('12', '积分过期提醒浮窗-点击'),
  GOODS_DETAIL_RECOMMEND_CLICK: new XEnumItem('13', '商品详情页-精品推荐-商品卡片-点击'),
  GOODS_DETAIL_RECOMMEND_CARD_EP: new XEnumItem('14', '商品详情页-精品推荐-商品卡片-曝光'),
  GOODS_HOT_SEARCH_LIST_CLICK: new XEnumItem('15', '商品搜索页-热度榜单-商品卡片-点击'),
  GOODS_HOT_SEARCH_LIST_EP: new XEnumItem('16', '商品搜索页-热度榜单-商品卡片-曝光'),
  GOODS_TIME_LIMIT_LIST_CLICK: new XEnumItem('17', '限时秒杀-商品卡片-点击'),
  GOODS_TIME_LIMIT_LIST_EP: new XEnumItem('18', '限时秒杀-商品卡片-曝光'),
  MINE_CREDIT_ENTRY_CLICK: new XEnumItem('19', '个人中心-信用卡入口-点击'),
  MYORDER_TOPBANNER_TO_POINTCENTER: new XEnumItem('33', '从短信进入我的订单页-顶部积分中心引流图-点击'),
  FOOT_BAR_THEMED_CLICK: new XEnumItem('34', '主底部导航栏-点击'),
  GOODS_RETURN_SCORE_LIST_EP: new XEnumItem('40', '等价全返-商品卡片-曝光'),
  HOME_PAGE_SOURCE_PLACE_EP: new XEnumItem('41', '首页资源位-曝光'),
  POINT_CENTER_PAGE_SOURCE_PLACE_EP: new XEnumItem('42', '积分中心资源位-曝光'),
  MINE_PAGE_SOURCE_PLACE_EP: new XEnumItem('43', '个人中心资源位-曝光'),
  GOODS_PROFILE_ARRIVE_BOTTOM_EP: new XEnumItem('44', '商详页滑动到底部-曝光'),
  GOODS_PROFILE_TOP_PICTURE_EP: new XEnumItem('45', '商详页顶部商品图片-曝光'),
  SEARCH_RESULT_GOODS_EP: new XEnumItem('46', '搜索结果-曝光'),
  MY_ORDER_LIST_ITEM_EP: new XEnumItem('47', '订单-曝光'),
  MY_ORDER_PAGE_SOURCE_PLACE_EP: new XEnumItem('48', '我的订单-资源位曝光'),
  CONVENIENT_BUY_GOODS_EP: new XEnumItem('49', '顺手买商品-曝光'),
  GOODS_WATERFALL_ADV_EP: new XEnumItem('50', '商品瀑布流-广告-曝光')
})

const ENUM_SERVICE_STATUS = new XEnum({
  WAIT_PROCESSING: new XEnumItem(1, '待处理'),
  WAIT_REFUND: new XEnumItem(2, '待退款'),
  REFUNDED_RECEIVED: new XEnumItem(3, '已退款'),
  FINIDH_AFTER_SALE: new XEnumItem(4, '售后完成'),
  REFUNDING: new XEnumItem(5, '退款中'),
  CANCEL_REFUND: new XEnumItem(-1, '取消售后')
})

const ENUM_SERVICE_TYPE = new XEnum({
  NOT_DELIVER_REFUND: new XEnumItem(5, '未发货退款'),
  DELIVERED_REFUND: new XEnumItem(7, '退货退款'),
  DELIVERED_REISSUE: new XEnumItem(8, '退货补发')
})

const ENUM_DIVERSION_PAGE_TYPE = new XEnum({
  PAY_SUCCESS: new XEnumItem(0, '支付成功页'),
  CUSTOMER: new XEnumItem(1, '客服页')
})

// 退货状态
const ENUM_RETURN_GOODS_STATUS = new XEnum({
  CANCEL: new XEnumItem(-1, '取消退货'),
  UNCHECKED: new XEnumItem(0, '待验收'),
  CHECKED: new XEnumItem(1, '验收合格'),
  UNQUALIFIED: new XEnumItem(2, '验收不合格'),
  NORETURN: new XEnumItem(3, '无退货')
})
export default {
  ENUM_GOODS_GROUP_TYPE,
  ENUM_MALL_BRAND,
  ENUM_DATA_REPORT_SOURCES,
  ENUM_WEBFUNNY_POINT_ID,
  ENUM_PAYMENT_TYPE,
  ENUM_ORDER_STATUS,
  ENUM_QUERY_ORDER_STATUS,
  ENUM_ORDER_PAY_STATUS,
  ENUM_PAGE_SOURCE,
  ENUM_APP_TYPE,
  ENUM_THEME_TYPE,
  ENUM_BARGAIN_STATUS,
  ENUM_ACTIVITY_TYPE,
  ENUM_SHARE_PIDS,
  ENUM_HOT_RANK_STATUS,
  ENUM_FILTER_GOODS_LIST,
  ENUM_SPRINGBOARD_TYPE,
  ENUM_LINK_TYPE,
  ENUM_GOODS_TYPE,
  ENUM_ORDER_TYPE,
  ENUM_ADVERTISEMENT_TYPE,
  ENUM_JUMP_MINIAPP_LINKS,
  ENUM_BRANCH,
  ENUM_ADVERTISE_URL_TYPE,
  ENUM_TRAFFIC_GUIDE_TYPE,
  ENUM_RP_EVENT_TYPE,
  ENUM_SERVICE_TYPE,
  ENUM_SERVICE_STATUS,
  ENUM_DIVERSION_PAGE_TYPE,
  ENUM_RETURN_GOODS_STATUS
}
